import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import "../styles/Navbar.sass";

const OverlayLink = ({ path, displayName, toggleBurgerMenu, ext }) => {
  return (
    <div className="mobileNavItem" onClick={toggleBurgerMenu}>
      {ext ? <a href={path}>{displayName}</a> : <Link to={path}>{displayName}</Link>}
    </div>
  );
};

OverlayLink.propTypes = {
  path: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  toggleBurgerMenu: PropTypes.func.isRequired,
  ext: PropTypes.bool
};

const Overlay = props => {
  return (
    <div
      className={
        `overlayContainer ${props.isOpen  ? 'overlayOpen' : ''} 
        ${props.scrolled ? 'scrolled' : ''}
        `
      }
    >
      <OverlayLink
        path={"/"}
        displayName="~"
        toggleBurgerMenu={props.toggleBurgerMenu}
      />
      <OverlayLink
        path={"/articles"}
        displayName="/articles"
        toggleBurgerMenu={props.toggleBurgerMenu}
      />
      <OverlayLink
        path={"https://discord.gg/TnqMR2P2rV"}
        displayName="/community"
        toggleBurgerMenu={props.toggleBurgerMenu}
      />
      <OverlayLink
        path={"mailto:khalil@khalilstemmler.com"}
        displayName="/contact"
        ext={true}
        toggleBurgerMenu={props.toggleBurgerMenu}
      />
      <OverlayLink
        path={"/resources"}
        displayName="/resources"
        toggleBurgerMenu={props.toggleBurgerMenu}
      />
      <OverlayLink
        path={"https://twitter.com/khalilstemmler"}
        displayName="/twitter"
        ext={true}
        toggleBurgerMenu={props.toggleBurgerMenu}
      />
      
    </div>
  );
};

export default Overlay;
