
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MobileNavigation from '../../mobile-navigation'
import "../styles/Navigation.sass"
import icon from '../../../../images/icons/mystery-icon-white.svg'
import NavLink from './NavLink'
import { colors } from '../../../../assets/colors'
import { useLocation } from '@reach/router';

const RetroBars = () => (
  <div className="retro-bars">
    {Object.keys(colors.retro).map((c) => (
      <div style={{
        backgroundColor: colors.retro[c]
      }}></div>
    ))}
  </div>
)

const Logo = () => (
  <a className="logo" href="https://khalilstemmler.com/">
    <img src={icon} />
    <p>khalilstemmler.com</p>
  </a>
)

const Links = () => {
  const location = useLocation();
  const resourcesLink = location.pathname === '/' ? '#resources' : '/resources';

  return (
    <div className="links">
      <NavLink to={resourcesLink} displayValue="Resources"/>
      <NavLink to="/articles" displayValue="Articles" />
      {/* <NavLink to="https://twitter.com/khalilstemmler" displayValue="X" />
      <NavLink to="http://github.com/stemmlerjs" displayValue="GitHub" />
      <NavLink to="https://discord.gg/MyhQXMvZCr" displayValue="Community" /> */}
    </div>
  )
}

function useNavigation () {
  const [scrolled, setScrolled] = useState(false);
  const addScrollEventListener = () => {
    typeof window !== 'undefined'
      && window.addEventListener('scroll', navOnScroll.bind(this))
  }

  const removeScrollEventListener = () => {
    typeof window !== 'undefined'
        && window.removeEventListener('scroll', navOnScroll.bind(this))
  }

  const navOnScroll = () => {
    if (window.scrollY > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    addScrollEventListener();
    return () => removeScrollEventListener()
  }, [])

  return { scrolled }
}

function Navigation (props) {
  const { scrolled } = useNavigation();
  const { rawMode } = props;
  
  return (
    <>
      <MobileNavigation
        scrolled={scrolled}
        topOffset={scrolled ? '16px' : '27px'}
      />

      {!rawMode ? (
        <div className={scrolled ? "navigation scroll" : "navigation"}>
          <div className="navigation-inner">
            <Logo/>
            <Links/>
          </div>
          {/* <RetroBars /> */}
        </div>
      ) : ''}
    </>
  )
}

export default Navigation;

Navigation.propTypes = {
  rawMode: PropTypes.bool
}