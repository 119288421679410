import React from 'react'
import { Link } from 'gatsby'

/**
 * 
 *I co-founded <a href="https://univjobs.ca">Univjobs</a> in 2017 and learned a lot about making code scale. At some 
  point during my work as a consultant and reading as many <Link to="/books">books</Link> on software as I could digest, 
  I've come to realize that there were a lot of pitfalls I could have avoided if I knew the patterns and principles to 
  guide me. That's what this site is about: things I think all professional software people should know.

  <span className="special-green">TypeScript</span> & <span className="special-green">Node.js</span> best practices for <span className="special-green">large-scale</span> applications. Learn to write <span className="special-green">scalable</span> (testable, flexible, maintainable) software.
 */

export default {
  subscriberCount: 20000,
  "main": {
    "description": (
      <span>
        I help coders master <a href="https://www.essentialist.dev/master-the-essentials-of-software-design-with-typescript" className="special-green">the essential 80-20</a> of software design, architecture & testing. Learn to write <span className="special-green">scalable</span> <span className="special-green">testable</span>software. <br/>
      </span>
    ),
    "join": (
      <p>
        Join 20,000+ other value-creating developers mastering what matters & building incredible careers.
      </p>
    ),
    "description-expanded": (
      <span className="subhero-text">
        I create <Link to="https://www.essentialist.dev/master-the-essentials-of-software-design-with-typescript">courses</Link>, <Link to="/resources">resources</Link>, 
        and <Link to="/articles">articles</Link> on advanced front-end and back-end topics like <Link to="https://khalilstemmler.com/articles/client-side-architecture/introduction/">front-end architecture</Link>, <Link to="/articles/categories/domain-driven-design/">Domain-Driven Design</Link>, <Link href="https://khalilstemmler.com/letters/3-steps-to-solve-most-design-problems/">effective object-oriented design</Link>, and <Link to="/articles/categories/enterprise-node-type-script/">Enterprise Node.js</Link>.
      </span>
    )
  },
  "currently": {
    "book": {
      "title": "Patterns of Enterprise Application Architecture",
      "author": "Martin Fowler"
    },
    "music": {
      "album": "Virgo's Maze",
      "artist": "Part Time"
    }
  }
}
